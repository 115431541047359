@use 'sass:map';
@use '@angular/material' as mat;

@mixin asc-dashboard-theme($theme, $themeLight) {
  $primaryLight: map.get($themeLight, primary);

   .mat-mdc-raised-button:not(:disabled).asc-module-button {
      background-color: mat.m2-get-color-from-palette($primaryLight, 800);
      color: mat.m2-get-color-from-palette($primaryLight, '900-contrast');
   }
}